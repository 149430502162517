import { get, put,Delete,post } from '../axios'

// 储值活动-列表
export const activityList = params => get(`/user/web/balance/activity/list`,params);
// 储值活动-新增
export const activityAdd = params => post(`/user/web/balance/activity/add`,params);
// 储值活动-删除
export const activityDel = params => Delete(`/user/web/balance/activity/delete/${params}`,params);

// 储值活动-详情
export const activityView = params => get(`/user/web/balance/activity/view/${params}`,params);
// 储值活动-编辑
export const activityUp = params => put(`/user/web/balance/activity/update`,params);

// 充值记录-列表
export const recordList = params => get(`/user/web/balance/record/list`,params);

// 充值说明-详情
export const descView = params => get(`/user/web/balance/desc/view`,params);
// 充值说明-编辑
export const descUpdate = params => put(`/user/web/balance/desc/update`,params);


